import React from "react";
import ReactDOM from "react-dom";
import update from "immutability-helper";
import Autosuggest from "react-autosuggest";
import BooklistSelectorHeader from "./booklistselectorheader";
import BooklistSelectorFooter from "./booklistselectorfooter"
import BooklistSelectorAutosuggestHeader from "./selector-autosuggest-header";
import BooklistSelectorAutosuggestClearButton from "./selector-autosuggest-clearbutton";
import SchoolWillOrderNotification from "../../controllers/school-will-order-notification";

const BASE_ELEMENT = "react-booklist-selector";

const DATA_TYPES = ["organizations", "locations", "periods", "group1", "group2", "group3", "booklists"];

const DATA_GROUPS = {
    organizations: { index: 0, call: "GetOrganizationsAsync", prefix: "organizationId" },
    locations: { index: 1, call: "GetDepartmentsByOrganizationIdAsync", prefix: "organizationId" },
    periods: { index: 2, call: "GetPeriods", prefix: "parentId" },
    group1: { index: 3, call: "GetGroups", prefix: "periodId" },
    group2: { index: 4, call: "GetGroups", prefix: "group1" },
    group3: { index: 5, call: "GetGroups", prefix: "group2" },
    booklists: { index: 6, call: "GetBooklists", prefix: "parentId", addressId: "addressId" }
};

// TODO: volgens mij moet deze op window gezet?
if (selectorStartsWithCity === true) {
    DATA_TYPES[0] = "locations";
    DATA_TYPES[1] = "organizations";

    DATA_GROUPS.locations = { index: 0, call: "GetDeliveryLocationsAsync", prefix: null };
    DATA_GROUPS.organizations = { index: 1, call: "GetActiveDepartmentsByCityAsync", prefix: "city" };
}

const dataBaseUrl = document.getElementById(BASE_ELEMENT).getAttribute("data-baseurl");
const dataOptions = JSON.parse(document.getElementById(BASE_ELEMENT).getAttribute("data-options"));
const isLandingPage = document.getElementById(BASE_ELEMENT).getAttribute("data-landingpage") === "true";
const isSso = document.getElementById(BASE_ELEMENT).getAttribute("data-sso") === "true";

const suggestionsContainerBorder = dataOptions.borderSuggestions ? " border" : "";
const suggestionsContainerBackground = dataOptions.suggestionsBackground ? " fill" : "";
const suggestionsContainerStyle = suggestionsContainerBorder + suggestionsContainerBackground;

class BookListSelector extends React.Component {
    constructor(props) {
        super(props);

        let valueState = [];
        let suggestionsState = [];
        let dataState = [];

        DATA_TYPES.map(() => {
            valueState.push("");
            suggestionsState.push([]);
            dataState.push([]);
        });

        this.preselected = [];
        this.buttons = [];
        this.inputs = [];
        this.autosuggest = [];

        let clearButtons = [];
        for (let i = 0; i <= 6; i++) {
            clearButtons = [...clearButtons, { isActive: false, disabled: false }];
        }

        this.state = {
            // data contains the results for each index from the list api
            data: dataState,
            // suggestions contains the (filtered) data for the inputs for each index
            suggestions: suggestionsState,
            // value contains the selected name for each input
            value: valueState,
            // selectedItems contains the selected item for each input
            selectedItems: [],
            selectedIndex: -1,
            lastItem: false,
            enableButton: false,
            booklistUrl: "",
            loading: true,
            renderSuggestions: false,
            categoryFilter: -1,
            clearButtons: clearButtons,
            displaySuggestions: false,
            addressId: null
        };

        this.onCategoryFilterChange = this.onCategoryFilterChange.bind(this);
        this.onAutosuggestCleared = this.onAutosuggestCleared.bind(this);
        this.onAutosuggestActivationToggle = this.onAutosuggestActivationToggle.bind(this);
    }

    componentDidMount() {
        if (selectorStartsWithCity === false) {

            var address = this.props.preselected["addressId"];
            Object.values(this.props.preselected)
                .map((value) => {
                    var item = value[Object.keys(value)[0]];
                    if (item && item != "" && value != address) {
                        this.preselected.push({ Id: item });
                    }
                });
        }
        this._getData(DATA_GROUPS[DATA_TYPES[0]], isLandingPage && this.preselected && this.preselected[0].Id !== undefined ? this.preselected[0].Id : null);
    }

    _getData(params, previousId) {
        let payloadApiCall = {};
        if (previousId) {
            payloadApiCall[params.prefix] = previousId;
        }

        if (this.state.addressId !== null) {
            payloadApiCall[params.addressId] = this.state.addressId;
        }

        if (params.index > 3) {
            // loading 2nd or 3rd layer groups not only need the parent group but also the periodId
            // if there's no period (very much the case with bad data) return
            if (!this.state.selectedItems[2]) return;
            payloadApiCall[DATA_GROUPS[DATA_TYPES[3]].prefix] = this.state.selectedItems[2].Id;
        }

        // TODO: JQUERY eruit
        return $.getJSON(`/api/ListApi/${params.call}/`, payloadApiCall).done(response => {
            this._getDataHandleResponse(params, response);
        });
    }

    _getLmlNextBookList(params, departmentId, year) {
        var addressId = this.state.addressId;
        if (!addressId) {
            var address = this.props.preselected["addressId"];
            addressId = address[Object.keys(address)[0]];
        }

        var payload = {
            departmentId: departmentId,
            year: year,
            addressId: addressId
        };
        return $.getJSON(`/api/ListApi/${params.call}/`, payload).done(response => {
            this._getDataHandleResponse(params, response);
        });
    }

    _getDataHandleResponse(params, resp) {
        // TODO: Make it more DRY
        const valStateUpdate = this.state.value.slice();
        const last = params.index === DATA_TYPES.length - 1;
        const isGetBookListsCall = params.call === 'GetBooklists';
        const shouldGetBooklist = resp.length < 1 && params.index < 6;

        if (isGetBookListsCall && resp.find(x => x.ShowPrivateOrderingPopup)) {
            SchoolWillOrderNotification.show();
            return;
        }

        if (params.index > 1 && this.state.selectedItems[1]) {
            this.setState({
                addressId: this.state.selectedItems[1].AddressId
            });
        }

        if (this.preselected[params.index]) {
            let canSelectFromList = false;
            let selectionFromList;
            resp.map(item => {
                if (item.Id === this.preselected[params.index].Id) {
                    canSelectFromList = true;
                    valStateUpdate[params.index] = item.Name;
                    selectionFromList = item;
                }
            });

            if (canSelectFromList && selectorStartsWithCity === false) {
                this._selectItem(selectionFromList, params.index);
            } else {
                this._getData(DATA_GROUPS[DATA_TYPES[params.index + 1]], this.preselected[params.index].Id);
            }
        } else if (resp.length === 1) {
            this._selectItem(resp[0], params.index);
            valStateUpdate[params.index] = resp[0].Name;
        } else if (resp.length > 1 && params.index > 0) {
            setTimeout(() => {
                if (this.inputs.length > 0 && this.inputs[params.index] !== undefined) {
                    this.inputs[params.index].focus();
                }
            }, 0);
        }

        // Show popup when GetPeriods returns an empty array
        if (params.index === 2 && resp.length === 0) {
            const modal = $('#no-booklist-modal');
            modal.modal('show');
        }

        if (shouldGetBooklist) {
            // remove empty items
            var selectedItems = this.state.selectedItems.filter(
                t => !(Object.keys(t).length === 0 && t.constructor === Object)
            );

            if (selectedItems.length > 0) {
                // get the last selectedItem (we don't know how many group layers there are)
                this._getData(DATA_GROUPS[DATA_TYPES[6]], selectedItems[selectedItems.length - 1].Id);
            }
        }

        const newState = this._rebuildDataState(params.index, resp);
        this.setState({
            loading: false,
            value: valStateUpdate,
            lastItem: last,
            data: newState,
            suggestions: newState,
            selectedIndex: params.index
        });
    }

    _selectItem(data, index) {
        let newState = [];

        if (data) {
            this._rebuildselectedItemsState(index);
            const enableBooklistButton = index === 6;

            this.setState({
                selectedItems: update(this.state.selectedItems, { [index]: { $set: data } }),
                clearButtons: update(this.state.clearButtons, { [index]: { disabled: { $set: true } } }),
                enableButton: enableBooklistButton
            });
            if (enableBooklistButton) {
                return this.setState(this._generateUrl(data));
            }
        } else {
            newState = undefined;
        }
        if (data.IsLMLNextResult) {
            this._getLmlNextBookList(DATA_GROUPS[DATA_TYPES[DATA_TYPES.length - 1]], data.Id, data.Url);
        }
        else {
            this._getData(DATA_GROUPS[DATA_TYPES[index + 1]], data.Id);
        }
    }

    _rebuildDataState(index, resp) {
        let newState = [];
        for (let i = 0; i <= index; i++) {
            newState[i] = this.state.data[i];
        }
        if (resp) {
            newState[index] = resp;
        }
        return newState;
    }

    _rebuildselectedItemsState(index) {
        let newSelectedItems = [];
        let newSuggestions = [];
        for (let i = 0; i <= index; i++) {
            if (this.state.selectedItems[i]) {
                newSelectedItems[i] = this.state.selectedItems[i];
                newSuggestions[i] = this.state.suggestions[i];
            } else {
                newSelectedItems[i] = {};
                newSuggestions[i] = [];
            }
        }
        this.setState({
            selectedItems: newSelectedItems,
            suggestions: newSuggestions
        });
    }

    _generateUrl(selectedItem) {
        const state = this.state.selectedItems;
        const urlPath = `${state[0].Url}/${state[2].Url}/${selectedItem.SchoolListNumber}/${selectedItem.Url}`;
        let booklistUrl = `${dataBaseUrl}/${urlPath}`;

        if (this.state.addressId !== null) {
            booklistUrl += `/loc/${this.state.addressId}`;
        }

        return {
            booklistUrl
        };
    }

    onAutosuggestInputChange(index, event, { newValue, method }) {
        let valStateUpdate = this.state.value.slice();

        valStateUpdate[index] = newValue;

        this.setState({
            value: valStateUpdate,
            displaySuggestions: true
        });
    }

    onSuggestionsFetchRequested(items, index, { value }) {
        let suggestions = [];

        if (value && value.slice(0, 1).match(/[a-z, -0-9]/i) == null) {
            value = " ";
        }

        items.map(item => {
            const itemNormalized = item.Name.match(/[a-z, -0-9]/gi)
                .join("")
                .trim()
                .toUpperCase();
            const valueNormalized = value
                .replace(/[(,)]/g, "")
                .toUpperCase()
                .trim();

            const hasMatchedValue = itemNormalized.search(valueNormalized) !== -1;
            // use category filter for index 0 (organization) and 1 (location/department) if the value is not -1 (no filter)
            if (
                (hasMatchedValue || !value) &&
                (index > 1 ||
                    (this.state.categoryFilter === -1 || // no filter selected
                        (item.OrganizationFilters && item.OrganizationFilters.indexOf(this.state.categoryFilter) >= 0))) // organization & department
            ) {
                suggestions.push(item);
            }
        });

        // first clear the suggestions and wait until the state has been set, next fill the suggestions (again) which will trigger the autosuggest
        // this is possibly a reactjs bug or maybe this is by design (ie not trigger the autosuggest if in the end there's no difference even if the state has been set)
        this.setState(
            {
                suggestions: update(this.state.suggestions, { [index]: { $set: [] } }),
                clearButtons: update(this.state.clearButtons, {
                    [index]: { disabled: { $set: false }, isActive: { $set: true } }
                }),
                renderSuggestions: true
            },
            () => {
                this.setState({
                    suggestions: update(this.state.suggestions, { [index]: { $set: suggestions } })
                })
            }
        );
    }

    onSuggestionSelected(index, event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
        this._selectItem(suggestion, index);
    }

    _clearfield(index) {
        let valStateUpdate = this.state.value.slice();
        let dataStateUpdate = this._rebuildDataState(index);
        this._rebuildselectedItemsState(index);

        for (let i = index; i < DATA_TYPES.length; i++) {
            valStateUpdate[i] = "";
        }

        this.setState({
            value: valStateUpdate,
            data: dataStateUpdate,
            selectedIndex: index,
            enableButton: false,
            clearButtons: update(this.state.clearButtons, {
                [index]: { disabled: { $set: false }, isActive: { $set: false } }
            })
        });
    }

    onAutosuggestInputClick(index, event) {
        this.setState({ displaySuggestions: true });

        this._clearfield(index);
        this.onSuggestionsFetchRequested(this.state.data[index], index, { value: ' ' });
    }

    onCategoryFilterChange(categoryFilterId, event) {
        this._clearfield(0);
        this.setState({
            clearButtons: update(this.state.clearButtons, { 0: { disabled: { $set: false }, isActive: { $set: true } } }),
            categoryFilter: categoryFilterId,
            renderSuggestions: true
        });

        // without timeout the input will not open
        setTimeout(() => {
            this.inputs[0].click();
            this.inputs[0].focus();
        }, 0);
    }

    onAutosuggestCleared(index, event) {
        this._clearfield(index);

        // without timeout the input will not open
        setTimeout(() => {
            this.inputs[index].focus();
        }, 0);
    }

    onAutosuggestActivationToggle(index, event) {
        const isActive = this.state.clearButtons[index].isActive;
        this.setState({
            clearButtons: update(this.state.clearButtons, {
                [index]: { disabled: { $set: false }, isActive: { $set: !isActive } }
            }),
            renderSuggestions: !isActive,
            displaySuggestions: true
        });

        if (!isActive) {
            this.onSuggestionsFetchRequested(this.state.data[index], index, { value: ' ' });
            this.inputs[index].focus();
        }
    }

    onAutosuggestBlur(index, event) {
        const currentTarget = event.currentTarget;
        setTimeout(() => {
            // check if the blur is realy outside the input+clearbutton
            // prevent that a click on the clearbutton triggers the blur action
            if (!currentTarget.contains(document.activeElement)) {
                this.setState({
                    clearButtons: update(this.state.clearButtons, { [index]: { isActive: { $set: false } } }),
                    renderSuggestions: false
                });
            }
        }, 0);
    }

    render() {
        return (
            <div className={"bookListSelector"}>
                <BooklistSelectorHeader landingpage={isLandingPage} sso={isSso} />
                {this.state.data.map((item, index) => {
                    if (item) {
                        if (
                            (item.length > 0 || Object.keys(item).length > 0) &&
                            (index <= this.state.selectedIndex || this.state.selectedIndex < 0)
                        ) {
                            const renderRowAsHeading = index === 0 && (isLandingPage || isSso) && selectorStartsWithCity === false;
                            const autosuggestId = `autosuggest${index.toString()}`;
                            const dataSfTag = `BookListSelector.${DATA_TYPES[index]}`;
                            const disabledInput =
                                (this.state.selectedIndex > 0 && index < this.state.selectedIndex) || this.state.enableButton;
                            const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                            const isMobile = iOS || window.innerWidth < 415;

                            const containerCSS = () => {
                                let classname = "bookListSelector__autosuggest-container";

                                if (
                                    dataOptions.hideFields &&
                                    ((this.props.preselected.organizationId && index === 0) ||
                                        (this.props.preselected.departmentId && index === 1))
                                ) {
                                    classname += " hidden";
                                }

                                if (disabledInput) {
                                    classname += " disabled";
                                }
                                if (isMobile) {
                                    classname += " iosmobile";
                                }

                                if (dataOptions.widthFixed) {
                                    classname += " width-fixed";
                                }

                                return classname;
                            };

                            if (renderRowAsHeading) {
                                return <h4 className="pt-0">{this.state.value[index]}</h4>;
                            }

                            return (
                                <div className={containerCSS()} key={autosuggestId} onBlur={this.onAutosuggestBlur.bind(this, index)}>
                                    <BooklistSelectorAutosuggestHeader name={selectorLabels[index]} />
                                    <Autosuggest
                                        suggestions={this.state.suggestions[index]}
                                        ref={autosuggest => {
                                            if (autosuggest !== null) {
                                                this.inputs[index] = autosuggest.input;
                                                this.autosuggest[index] = autosuggest;

                                                // TODO: Onderstaande uit de render method halen
                                                autosuggest.suggestionsContainer.className += suggestionsContainerStyle;
                                            }
                                        }}
                                        key={autosuggestId}
                                        id={autosuggestId}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this, item, index)}
                                        getSuggestionValue={suggestion => {
                                            return suggestion.Name;
                                        }}
                                        renderSuggestion={suggestion => <span>{suggestion.Name}</span>}
                                        inputProps={{
                                            id: `input-${autosuggestId}`,
                                            "data-sf": `${dataSfTag}`,
                                            value: this.state.value[index],
                                            onChange: this.onAutosuggestInputChange.bind(this, index),
                                            onClick: this.onAutosuggestInputClick.bind(this, index),
                                            placeholder: this.props.placeholders[index]
                                        }}
                                        onSuggestionSelected={this.onSuggestionSelected.bind(this, index)}
                                        focusInputOnSuggestionClick={true}
                                        shouldRenderSuggestions={() => {
                                            if (!this.state.renderSuggestions && !this.state.displaySuggestions) {
                                                return false;
                                            }

                                            return index >= this.state.selectedIndex;
                                        }}
                                    />

                                    <BooklistSelectorAutosuggestClearButton
                                        index={index}
                                        isActive={this.state.clearButtons[index].isActive}
                                        disabled={this.state.clearButtons[index].disabled}
                                        onClear={this.onAutosuggestCleared}
                                        onActivationToggle={this.onAutosuggestActivationToggle}
                                    />
                                </div>
                            );
                        }
                    }
                })}
                <BooklistSelectorFooter state={this.state} booklistImport={this.props.booklistImport} buyBooks={this.props.buyBooks} baseElement={BASE_ELEMENT} />
            </div>
        );
    }
}

ReactDOM.render(
    <BookListSelector placeholders={placeholders} preselected={preselected} booklistImport={booklistImport} buyBooks={buyBooks} />,
    document.getElementById(BASE_ELEMENT)
);